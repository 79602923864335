import request from '@/service/request'

//  新建合同登记(草稿箱)
const addContractApi = (params) => {
    return request.post('/teacher/contract/create', params)
}

//  提交合同
const submitContractApi = (params) => {
    return request.post('/teacher/contract/submit', params)
}

//  审核合同
const auditContractApi = (params) => {
    return request.post('/teacher/contract/audit', params)
}

//  合同经办
const transactContractApi = (params) => {
    return request.post('/teacher/contract/do', params)
}

//  分页获取（未提交、已提交、需部门审核）
const contractPageApi = (params) => {
    return request.post('/teacher/contract/pageByAudit', params)
}

//  分页获取（经办人）
const transctPageApi = (params) => {
    return request.post('/teacher/contract/pageByHandle', params)
}

// 根据id获取
const getContractByIdApi = (params) => {
  return request.post('/teacher/contract/getById/' + params)
}

// 删除草稿箱
const delContractByIdApi = (params) => {
    return request.post('/teacher/contract/delete/' + params)
}

// 撤回合同
const undoContractByIdApi = (params) => {
    return request.post('/teacher/contract/undo/' + params)
}

export {
    addContractApi,
    submitContractApi,
    contractPageApi,
    getContractByIdApi,
    delContractByIdApi,
    auditContractApi,
    transctPageApi,
    transactContractApi,
    undoContractByIdApi
}
