

// 合同单位列表
export const contractUnitList = ['甲方', '乙方']

// 状态筛选列表
export const queryInfoStatusList = [
    {
    label: '流程中',
    values:'[-1,0,1,2,3,4,6]'
    },
    {
    label: '办结文件',
    values:'[5]'
    },
    {
    label: '否决文件',
    values:'[-2]'
    }
]
 

// 经办状态
export const doStatusList = [
    [1,'已办结'],
    [2,'待归还'],
]

// 校领导审批状态
export const leaderStatusList = [
    [-1,'否决'],
    [0,'回滚修改'],
    [1,'审核中'],
    [2,'审核通过']
]
// 部门领导审批状态
export const deptStatusList = [
    [-1,'否决'],
    [0,'回滚修改'],
    [1,'审核中'],
    [2,'审核通过']
]


// 合同审批状态
export const contractStatusList = [
    [-2,'否决'],
    [-1,'回滚修改'],
    [0,'撤回'],//已废弃
    [1,'创建'],
    [2,'部门审核中'],
    [3,'校领导审核中'],
    [4,'待办理'],
    [5,'已办理'],
    [6,'待归还']
]