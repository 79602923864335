<template>
    <el-dialog :visible.sync="show" :close-on-click-modal="false" width="1200px" :before-close="cancel">
        <div class="container">
            <div class="table-form-header" style=" text-align: center; padding-bottom:10px; font-size: 20px;">
                <h3 class="title ">合同审核登记表</h3>
            </div>
            <el-form ref="form" :model="formData" :rules='rules'>
                <table class="table " border="1"
                    style=" width: 100%; border:#88B6E0 ; border-collapse: collapse; table-layout: fixed;font-size:15px;">
                    <!-- 创建人 -->
                    <tr>
                        <td colspan="2" class="title ">申请时间</td>
                        <td colspan="2">
                            <el-date-picker v-if="isApply && !isApplyOnlyRead" v-model="formData.applicationTime"
                                type="date" value-format="yyyy-MM-dd" placeholder="请选择"></el-date-picker>
                            <div v-else>{{ formData.applicationTime }}</div>
                        </td>
                        <td colspan="2" class="title ">合同编号</td>
                        <td colspan="2">
                            <el-input v-if="isApply && !isApplyOnlyRead" v-model="formData.contractNo" placeholder="请输入" />
                            <div v-else>{{ formData.contractNo }}</div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="title  ">合同名称</td>
                        <td colspan="6">
                            <el-input v-if="isApply && !isApplyOnlyRead" v-model="formData.contractName"
                                placeholder="请输入" />
                            <div v-else>{{ formData.contractName }}</div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="title ">申请单位</td>
                        <td colspan="6">

                            <el-input v-if="isApply && !isApplyOnlyRead" v-model="formData.supplyUnit" placeholder="请输入" />
                            <div v-else>{{ formData.supplyUnit }}</div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="title ">经办人</td>
                        <td colspan="2">
                            <el-autocomplete v-if="isApply && !isApplyOnlyRead" v-model="formData.doUserName"
                                :fetch-suggestions="querySearchAsync" placeholder="请输入经办人名字" size="small" clearable
                                @select="(e) => handleSelect(e, 'apply')" style="display: inline-block;" />
                            <div v-else>{{ formData.doUserName }}</div>
                        </td>
                        <td colspan="2" class="title ">联系方式</td>
                        <td colspan="2">
                            <el-input v-if="isApply && !isApplyOnlyRead" v-model="formData.doPhone" placeholder="请输入" />
                            <div v-else>{{ formData.doPhone }}</div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="title ">申请人</td>
                        <td colspan="2">
                            <el-input v-if="isApply && !isApplyOnlyRead" v-model="formData.supplyUserName"
                                placeholder="请输入项目负责人" />
                            <div v-else>{{ formData.supplyUserName }}</div>
                        </td>
                        <td colspan="2" class="title ">联系方式</td>
                        <td colspan="2">
                            <el-input v-if="isApply && !isApplyOnlyRead" v-model="formData.supplyPhone" placeholder="请输入" />
                            <div v-else>{{ formData.supplyPhone }}</div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="title ">有效期限</td>
                        <td colspan="6">
                            <el-input v-if="isApply && !isApplyOnlyRead" v-model="formData.timeLimit"
                                placeholder="建议写法“自合同签订之日起——年月日”，合同文本需保持一致。" />
                            <div v-else>{{ formData.timeLimit }}</div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="title ">合同金额</td>
                        <td colspan="2">
                            <el-input v-if="isApply && !isApplyOnlyRead" v-model="formData.contractMoney"
                                placeholder="如有金额，请以万为单位" />
                            <div v-else>{{ formData.contractMoney }}</div>
                        </td>
                        <td colspan="2" class="title  ">经费类别</td>
                        <td colspan="2">
                            <template v-if="isApply && !isApplyOnlyRead">
                                <el-radio-group v-model="formData.moneyType">
                                    <el-radio label="收入类"></el-radio>
                                    <el-radio label="支出类"></el-radio>
                                    <el-radio label="无"></el-radio>
                                </el-radio-group>
                            </template>
                            <template v-else>
                                <div> {{ formData.moneyType }}</div>
                            </template>

                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="title ">经费来源</td>
                        <td colspan="2">
                            <el-input v-if="isApply && !isApplyOnlyRead" v-model="formData.moneyFrom"
                                placeholder="如不涉及具体经费，请填写“无”" />
                            <div v-else> {{ formData.moneyFrom }}</div>
                        </td>
                        <td colspan="2" class="title ">合同份数</td>
                        <td colspan="2">
                            <el-input-number v-if="isApply && !isApplyOnlyRead" class="hasBorder"
                                v-model="formData.contractCount" :min="1" :max="10" label="描述文字"></el-input-number>
                            <div v-else> {{ formData.contractCount }}</div>
                        </td>
                    </tr>
                    <tr>
                        <td :rowspan="contractRowspan" colspan="2" class="title ">合作单位名称</td>
                        <td class="title ">甲方</td>
                        <td colspan="5">
                            <el-input v-if="isApply && !isApplyOnlyRead" v-model="formData.contractUnit[0][1]"
                                placeholder="请输入" />
                            <div v-else> {{ formData.contractUnit[0][1] }}</div>
                        </td>
                    </tr>
                    <tr>
                        <td class="title ">乙方</td>
                        <td colspan="5">
                            <el-input v-if="isApply && !isApplyOnlyRead" v-model="formData.contractUnit[1][1]"
                                placeholder="请输入" />
                            <div v-else>{{ formData.contractUnit[1][1] }}</div>

                        </td>
                    </tr>
                    <tr v-for="(item, index) in formData.contractUnit.slice(2)" :key="index">
                        <td class="title  t-a-c ">
                            <el-select v-if="isApply && !isApplyOnlyRead" v-model="item[0]" clearable filterable
                                size="small">
                                <el-option v-for="( item1, index1 ) in  contractUnitList " :key="index1" :label="item1"
                                    :value="item1" />
                            </el-select>
                            <div v-else>{{ item[0] }}</div>
                        </td>
                        <td colspan="5">
                            <div v-if="isApply && !isApplyOnlyRead" style="display: flex; align-items: center;">
                                <el-input v-model="item[1]" placeholder="请输入" />
                                <i @click="delContactUnit" style="color:#FE8903 ;" class="el-icon-remove-outline"></i>
                            </div>
                            <div v-else>
                                {{ item[1] }}
                            </div>
                        </td>
                    </tr>
                    <tr v-if="isApply && !isApplyOnlyRead">
                        <td colspan="6" style="text-align: right;">
                            <el-button type="text" @click="addContactUnit">添加</el-button>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="title ">合同附件</td>
                        <td colspan="6">
                            <uploader-zip-file @update:url="updateUrl" :url="formData.contractFile"
                                :isDelete="isApply && !isApplyOnlyRead" />
                            <el-button v-if="formData.contractFile" :disabled="downLoadZipFlag" type="text"
                                @click="downLoadZip(formData.contractFile)">
                                点击下载合同附件
                            </el-button>
                        </td>
                    </tr>
                    <!-- 部门审核人 -->
                    <tr>
                        <td rowspan="2" colspan="2" class="title ">部门意见</td>
                        <td colspan="1" class="title ">审核人</td>
                        <td colspan="2">
                            <el-autocomplete v-if="isApply && !isApplyOnlyRead" v-model="formData.deptUserName"
                                :fetch-suggestions="querySearchAsync" placeholder="请输入部门审核人名字" size="small" clearable
                                @select="(e) => handleSelect(e, 'dept')" style="display: inline-block;" />
                            <div v-else>{{ formData.deptUserName }}</div>
                        </td>
                        <td class="title ">审核状态</td>
                        <td colspan="2">
                            <div v-if="!isDept || isDeptOnlyRead"> {{
                                formData.deptStatus ? deptStatusList[formData.deptStatus + 1][1] : '' }}
                            </div>
                            <div v-else>
                                <el-select v-model="formData.deptStatus" clearable filterable size="small">
                                    <el-option v-for="( item, index ) in  deptStatusList " :key="index" :label="item[1]"
                                        :value="item[0]" />
                                </el-select>
                            </div>
                        </td>
                    </tr>
                    <!-- 校领导审核人 -->
                    <tr>
                        <td colspan="6" v-if="isDept || formData.deptRemark">
                            <el-input v-if="isDept || isDeptOnlyRead" v-model="formData.deptRemark" placeholder="请输入" />
                            <div v-else>{{ formData.deptRemark }}</div>
                        </td>
                    </tr>
                    <tr>
                        <td rowspan="2" colspan="2" class="title ">校领导意见</td>
                        <td colspan="1" class="title ">审核人</td>
                        <td colspan="2">
                            <el-autocomplete v-if="isApply && !isApplyOnlyRead" v-model="formData.leaderUserName"
                                :fetch-suggestions="querySearchAsync" placeholder="请输入校领导审核人名字" size="small" clearable
                                @select="(e) => handleSelect(e, 'leader')" style="display: inline-block;" />
                            <div v-else>{{ formData.leaderUserName }}</div>
                        </td>
                        <td class="title ">审核状态</td>
                        <td colspan="2">
                            <div v-if="!isLeader || isLeaderOnlyRead"> {{
                                formData.leaderStatus ? leaderStatusList[formData.leaderStatus + 1][1] : '' }}
                            </div>

                            <div v-else>
                                <el-select v-model="formData.leaderStatus" clearable filterable size="small">
                                    <el-option v-for="( item, index ) in  leaderStatusList " :key="index" :label="item[1]"
                                        :value="item[0]" />
                                </el-select>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6" v-if="isLeader || formData.leaderRemark">
                            <el-input v-if="isLeader || isLeaderOnlyRead" v-model="formData.leaderRemark"
                                placeholder="请输入" />
                            <div v-else>{{ formData.leaderRemark }}</div>
                        </td>
                    </tr>
                    <!-- 经办人 -->
                    <tr>
                        <td colspan="2" class="title ">办理状态</td>
                        <template v-if="formData.doStatus == 2">
                            <td colspan="2" v-if="isDoUser && !isDoUserOnlyRead">
                                <el-radio-group v-model="formData.doStatus">
                                    <el-radio :label="1">已办结</el-radio>
                                    <el-radio :label="2">待归还</el-radio>
                                </el-radio-group>
                            </td>
                            <td colspan="2" v-else>{{ formData.doStatus != null ? doStatusList[formData.doStatus - 1][1] :
                                ''
                            }}</td>
                            <td colspan="2" class="title ">归还时间
                            </td>
                            <td colspan="2">
                                <el-date-picker v-if="isDoUser && !isDoUserOnlyRead && !formData.returnDate"
                                    v-model="formData.returnDate" type="date" value-format="yyyy-MM-dd"
                                    placeholder="请选择"></el-date-picker>
                                <div v-else>{{ formData.returnDate }}</div>
                            </td>
                        </template>
                        <template v-else>
                            <td colspan="6" v-if="isDoUser && !isDoUserOnlyRead">
                                <el-radio-group v-model="formData.doStatus">
                                    <el-radio :label="1">已办结</el-radio>
                                    <el-radio :label="2">待归还</el-radio>
                                </el-radio-group>
                            </td>
                            <td colspan="6" v-else>{{ formData.doStatus != null ? doStatusList[formData.doStatus - 1][1] :
                                ''
                            }}</td>
                        </template>
                    </tr>
                    <tr>
                        <td colspan="2" class="title ">办理事项备注</td>
                        <td colspan="6">
                            <el-input v-if="isDoUser && !isDoUserOnlyRead" v-model="formData.doRemark" placeholder="请输入" />
                            <div v-else>{{ formData.doRemark }}</div>
                        </td>
                    </tr>
                </table>
            </el-form>
            <div class="footer">
                <el-button @click="close" size="small" plain>
                    取消
                </el-button>
                <el-button v-if="!id || (isApply && formData.contractStatus === 2)" @click="create" type="primary"
                    size="small" plain>
                    保存到草稿箱
                </el-button>
                <el-button v-if="isApply" @click="submit" type="primary" size="small">
                    提交
                </el-button>
                <el-button v-if="isDept || isLeader" @click="audit" type="primary" size="small">
                    确认审批
                </el-button>
                <el-button v-if="isDoUser" @click="transact" type="primary" size="small">
                    办理
                </el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import dialog from '@/vue/mixins/dialog'
import userAutocomplete from '@/vue/mixins/user-autocomplete'
import UploaderZipFile from '@/components/common/components/UploaderZipFile.vue'
import { leaderStatusList, deptStatusList, contractUnitList, doStatusList } from './option'
import { addContractApi, submitContractApi, getContractByIdApi, auditContractApi, transactContractApi } from '@/api/contract.js'

export default {
    mixins: [dialog, userAutocomplete],
    components: { UploaderZipFile },
    name: 'DialogAddContract',
    data() {
        return {
            id: '',
            leaderStatusList,
            deptStatusList,
            contractUnitList,
            doStatusList,
            contractRowspan: 3,
            downLoadZipFlag: false,
            userId: null,
            isApply: true,
            isApplyOnlyRead: false,
            isDept: false,
            isDeptOnlyRead: true,
            isLeader: false,
            isLeaderOnlyRead: true,
            isDoUser: false,
            isDoUserOnlyRead: true,
            rules: {
            },
            formData: {
                id: null,
                applicationTime: null,
                contractNo: null,
                contractName: null,
                supplyUnit: null,
                doUserId: null,
                doUserName: null,
                doPhone: null,
                doStatus: null,
                doRemark: null,
                returnDate: null,
                supplyUserName: null,
                supplyUnit: null,
                supplyPhone: null,
                contractUnit: [
                    ['甲方', null],
                    ['乙方', null]
                ],
                //存放甲方、乙方的数据Json
                timeLimit: null,
                contractStatus: null,
                contractCount: null,
                contractMoney: null,
                moneyType: null,
                moneyFrom: null,
                deptStatus: null,
                deptUserId: null,
                deptUserName: null,
                deptRemark: null,
                leaderUserId: null,
                leaderUserName: null,
                leaderStatus: null,
                leaderRemark: null,
                createUserId: null,
                contractFile: null,
            }
        }
    },

    async mounted() {
        await this.init()
    },
    methods: {
        // 获取当前用户在流程中的角色 
        // 申请人、部门审核人可以为同一人，但是校领导不能和申请人、部门审核人并存
        getRole() {
            this.userId = this.$store.state.login.userInfo.id

            this.userId == this.formData.createUserId ? this.isApply = true : this.isApply = false;
            this.userId == this.formData.deptUserId ? this.isDept = true : this.isDept = false;
            this.userId == this.formData.leaderUserId ? this.isLeader = true : this.isLeader = false;
            this.userId == this.formData.doUserId ? this.isDoUser = true : this.isDoUser = false;

            if (!this.isApply) {
                this.contractRowspan = this.formData.contractUnit.length
            }
        },
        // 获取当前用户是否只读
        // 创建人只读 contractStatus [2,3,4,5,6]
        getIsOnlyRead() {

            // 全部只读 查看详情
            if (this.title === 'onlyRead') {

                this.isApplyOnlyRead = true
                this.isDeptOnlyRead = true
                this.isLeaderOnlyRead = true
                this.isDoUserOnlyRead = true

                this.contractRowspan = this.formData.contractUnit.length

                return;
            }

            // 创建人只读
            let applyStatusArr = [2, 3, 4, 5, 6]
            if (applyStatusArr.includes(this.formData.contractStatus)) {
                this.isApplyOnlyRead = true
                this.contractRowspan = this.formData.contractUnit.length
            }
            // 部门审核人只读
            if (this.formData.contractStatus === 2) {
                this.isDeptOnlyRead = false
            }
            // 校领导审核人只读
            if (this.formData.contractStatus === 3) {
                this.isLeaderOnlyRead = false
            }
            // 经办人只读
            if (this.formData.contractStatus === 4 || this.formData.contractStatus === 6) {
                this.isDoUserOnlyRead = false
            }


        },
        // 下载课件
        downLoadZip(filePath) {
            this.downLoadZipFlag = true
            const path = `${process.env.VUE_APP_IMG_URL}${filePath}`

            // window.open(path.replace(/\([^\)]*\)/g, ''))
            window.open(path)
            this.downLoadZipFlag = false
        },
        // 更新文件url
        updateUrl(url) {
            this.formData.contractFile = url
        },
        // 文件上传
        upLoadFile() { },
        // 删除合作单位
        delContactUnit() {
            this.formData.contractUnit.pop(['乙方', null])
            this.contractRowspan = this.formData.contractUnit.length + 1
            console.log(this.formData.contractUnit)
        },
        // 新增合作单位
        addContactUnit() {
            this.formData.contractUnit.push(['乙方', null])
            this.contractRowspan = this.formData.contractUnit.length + 1
            console.log(this.formData.contractUnit)
        },
        // 选项
        handleSelect(val, flag) {
            switch (flag) {
                case 'apply':
                    this.formData.doUserId = val.id
                    this.formData.doUserName = val.realName
                    break;
                case 'dept':
                    this.formData.deptUserId = val.id
                    this.formData.deptUserName = val.realName
                    break; case 'leader':
                    this.formData.leaderUserId = val.id
                    this.formData.leaderUserName = val.realName
                    break;
                default:
                    break;
            }
        },
        // 新增合同到草稿箱
        create() {
            if (!this.checked()) return;

            this.formData.id = this.id
            this.formData.contractUnit = JSON.stringify(this.formData.contractUnit)

            addContractApi(this.formData).then((res) => {

                if (res.success) {
                    this.$message.success("保存成功！")
                    this.init()

                }



            })
        },
        // 提交合同
        submit() {
            if (!this.checked()) return;

            this.formData.id = this.id
            this.formData.contractUnit = JSON.stringify(this.formData.contractUnit)

            submitContractApi(this.formData).then((res) => {
                if (res.success) {
                    this.$message.success("提交成功！")
                    this.init()
                }
            })
        },
        // 合同校验
        checked() {
            if (!this.formData.contractName) {
                this.$message.error('请填写合同名称')
                return false;
            }
            return true;
        },
        // 审核合同
        audit() {
            let data = {
                id: this.formData.id,
                remark: this.isDept ? this.formData.deptRemark : this.formData.leaderRemark,
                status: this.isDept ? this.formData.deptStatus : this.formData.leaderStatus,
                type: this.isDept ? 1 : 2,
            }

            auditContractApi(data).then((res) => {
                if (res.success) {
                    this.$message.success("提交成功！")
                    this.init()
                }
            })

        },
        // 经办合同
        transact() {
            let data = {
                id: this.formData.id,
                remark: this.formData.doRemark,
                returnDate: this.formData.returnDate,
                status: this.formData.doStatus,
            }
            transactContractApi(data).then((res) => {
                if (res.success) {
                    this.$message.success("提交成功！")
                    this.init()
                }
            })
        },
        // 初始化
        async init() {
            this.$nextTick(async () => {
                if (this.id) {
                    await this.getDataById(this.id, getContractByIdApi)
                    this.formData.contractUnit = JSON.parse(this.formData.contractUnit)
                    if (this.formData.contractUnit === null) {
                        this.formData.contractUnit = [
                            ['甲方', null],
                            ['乙方', null]
                        ]
                    }
                    this.formData.contractUnit.length > 2 ? this.contractRowspan = this.formData.contractUnit.length + 1 : '';
                    this.getRole()
                    this.getIsOnlyRead()
                } else {
                }
            })
        },
        // 关闭弹窗
        close() {
            this.show = false
        }

    }
}
</script>

<style lang="scss" scoped>
.container {
    padding: 20px !important;
    background: #fff;

    td {
        padding: 5px;
        line-height: 40px;
    }

    .t-a-c {

        ::v-deep .el-input__inner {
            text-align: center !important;
            color: #076AC6;
            font-size: 15px;
        }

    }

    ::v-deep .el-select-dropdown__item {
        text-align: center !important;
    }

    ::v-deep input {
        border: none !important;
    }

    .hasBorder {
        ::v-deep input {
            border: 1px solid #DCDFE6 !important;
        }
    }

    .title {
        color: #076AC6;
        text-align: center;
    }

    .flex-end {
        display: flex;
        justify-content: end;
    }


    .footer {
        margin: 50px 0;
        display: flex;
        justify-content: center;

        button {
            margin-right: 20px;

        }
    }
}
</style>